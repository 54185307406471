<!-- 表格列表项（行）的操作按钮列表，当大于两个时，第三个及以后的按钮会折叠进...号 -->
<!-- banned: true 可以禁用选项 -->

<template>
    <ul class="action-list" v-if="listFine">
        <!-- 前两个菜单 -->
        <template v-for="item in first2Items">
            <el-button type="text" :key="item.label || item.name || item"
                @click.stop="handleItemClick(item)">
                {{ item.label }}</el-button>
        </template>

        <!-- 省略符号 -->
        <el-popover v-if="restItems.length" class="more-btn" popper-class="operate-box"
            :offset="offset"
        >
            <ul>
                <template v-for="item in restItems">
                    <li type="text" :key="item.label || item.name || item"
                        @click.stop="handleItemClick(item)" class="rest-item">
                        {{ item.label }}</li>
                </template>
            </ul>

            <el-button type="text" slot="reference" icon="el-icon-more"></el-button>
        </el-popover>
    </ul>
</template>

<script>
export default {
    name: 'ActionList',

    props: {
        list: {
            type: Array,
            require: true,
            default: () => []
        },
        offset: {
            type: Number,
            default: 0
        }
    },

    data () {
        return {}
    },

    computed: {
        realList () {
            return this.list.filter(item => !item.banned)
        },

        listFine () { return this.realList.length },

        // 前两个菜单
        first2Items () {
            return this.listFine ? this.realList.slice(0, 2) : []
        },

        // 受否需要显示省略符号（菜单大于3个）
        showMore () {
            return this.realList.length > 2
        },

        // 第三个及以后的菜单
        restItems () {
            return this.showMore ? this.realList.slice(2) : []
        }
    },

    methods: {

        handleItemClick (item) {
            this.$emit("action", item)
        },

    }
}
</script>

<style lang='scss' scoped>
    .more-btn {
        margin-left: 10px;
    }
</style>